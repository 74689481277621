import { StatusAlert, StatusAlertWithHeader } from 'components/ui/Alert';
import { AlertStatus } from 'components/ui/enum/alert.enum';
import { ELinkageNSB } from 'pages/QRCODE/constant';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  IChangePasswordSsoRequest,
  ICheckExistingUserSsoRequest,
  ICheckExistingUserSsoResponse,
  IForgotPasswordForm,
  IForgotPasswordSsoRequest,
  IForgotPasswordSsoResponse,
  IGetServiceAccessTokenRequest,
  IGetUserDetailResponse,
  ILoginSsoRequest,
  IRegisterForm,
  IRegisterSsoRequest,
  IResetPasswordForm,
  IResetPasswordSsoRequest,
} from 'shared/modules/auth/login-sso';
import axios from 'shared/utils/axios';
import { toQueryString } from 'shared/utils/query-string';
import i18next from '../i18n';

export enum LocalStorageKey {
  TOKEN = 'token',
  EXPIRES = 'expires',
  TOKEN_SSO = 'token-sso',
  TOKEN_THAI_ID = 'token-thai-id',
  EXPIRES_THAI_ID = 'expires-thai-id',
  THAI_ID_CODE = 'thai-id-code',
  THAI_ID_CODE_GET = 'thai-id-code-get',
}

export enum GetThaiPid {
  THAI_ID_PID = 'thai-id-pid',
}

// action
export enum ELoginAction {
  REQUEST = 'LOGIN_REQUEST',
  SUCCESS = 'LOGIN_SUCCESS',
  FAILED = 'LOGIN_FAILED',
  NOT_LOGIN = 'NOT_LOGIN',
  ALREADY_LOGIN = 'ALREADY_LOGIN',
}

export enum ELogoutAction {
  REQUEST = 'LOGOUT_REQUEST',
  SUCCESS = 'LOGOUT_SUCCESS',
  FAILED = 'LOGOUT_FAILED',
}

export enum ESetPassword {
  REQUEST = 'SET_PASSWORD_REQUEST',
  SUCCESS = 'SET_PASSWORD_SUCCESS',
  FAILED = 'SET_PASSWORD_ERROR',
}

export enum EWebReport {
  REQUEST = 'WEB_REPORT_REQUEST',
  SUCCESS = 'WEB_REPORT_SUCCESS',
  FAILED = 'WEB_REPORT_ERROR',
}

export enum EForgotPassword {
  REQUEST = 'FORGOT_PASSWORD_REQUEST',
  SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FAILED = 'FORGOT_PASSWORD_FAILED',
}

export enum EResetPassword {
  REQUEST = 'RESET_PASSWORD_REQUEST',
  SUCCESS = 'RESET_PASSWORD_SUCCESS',
  FAILED = 'RESET_PASSWORD_FAILED',
  CLEAR_STORE = 'CLEAR_STORE',
}

export enum EValidateUserToken {
  REQUEST = 'VALIDATE_TOKEN_REQUEST',
  SUCCESS = 'VALIDATE_TOKEN_SUCCESS',
  FAILED = 'VALIDATE_TOKEN_FAILED',
}

export enum ENewLoginAction {
  EMAIL_REQUEST = 'EMAIL_REQUEST',
  EMAIL_SUCCESS = 'EMAIL_SUCCESS',
  EMAIL_FAILED = 'EMAIL_FAILED',
  OTP_REQUEST = 'OTP_REQUEST',
  OTP_SUCCESS = 'OTP_SUCCESS',
  OTP_FAILED = 'OTP_FAILED',
  PASSWORD_REQUEST = 'PASSWORD_REQUEST',
  PASSWORD_SUCCESS = 'PASSWORD_SUCCESS',
  PASSWORD_FAILED = 'PASSWORD_FAILED',
}

// NOTE: SSO
export enum ELoginSSOAction {
  LOGIN_SSO_REQUEST = 'LOGIN_SSO_REQUEST',
  LOGIN_SSO_SUCCESS = 'LOGIN_SSO_SUCCESS',
  LOGIN_SSO_FAILED = 'LOGIN_SSO_FAILED',
  LOGOUT_SSO_REQUEST = 'LOGOUT_SSO_REQUEST',
  LOGOUT_SSO_SUCCESS = 'LOGOUT_SSO_SUCCESS',
  LOGOUT_SSO_FAILED = 'LOGOUT_SSO_FAILED',
  USER_DETAIL_SSO_REQUEST = 'USER_DETAIL_SSO_REQUEST',
  USER_DETAIL_SSO_SUCCESS = 'USER_DETAIL_SSO_SUCCESS',
  USER_DETAIL_SSO_FAILED = 'USER_DETAIL_SSO_FAILED',
  GET_SERVICE_ACCESS_TOKEN_SSO_REQUEST = 'GET_SERVICE_ACCESS_TOKEN_SSO_REQUEST',
  GET_SERVICE_ACCESS_TOKEN_SSO_SUCCESS = 'GET_SERVICE_ACCESS_TOKEN_SSO_SUCCESS',
  GET_SERVICE_ACCESS_TOKEN_SSO_FAILED = 'GET_SERVICE_ACCESS_TOKEN_SSO_FAILED',
  CHANGE_PASSWORD_SSO_REQUEST = 'CHANGE_PASSWORD_SSO_REQUEST',
  CHANGE_PASSWORD_SSO_SUCCESS = 'CHANGE_PASSWORD_SSO_SUCCESS',
  CHANGE_PASSWORD_SSO_FAILED = 'CHANGE_PASSWORD_SSO_FAILED',
  FORGOT_PASSWORD_SSO_REQUEST = 'FORGOT_PASSWORD_SSO_REQUEST',
  FORGOT_PASSWORD_SSO_SUCCESS = 'FORGOT_PASSWORD_SSO_SUCCESS',
  FORGOT_PASSWORD_SSO_FAILED = 'FORGOT_PASSWORD_SSO_FAILED',
  RESET_PASSWORD_SSO_REQUEST = 'RESET_PASSWORD_SSO_REQUEST',
  RESET_PASSWORD_SSO_SUCCESS = 'RESET_PASSWORD_SSO_SUCCESS',
  RESET_PASSWORD_SSO_FAILED = 'RESET_PASSWORD_SSO_FAILED',
  REGISTER_SSO_REQUEST = 'REGISTER_SSO_REQUEST',
  REGISTER_SSO_SUCCESS = 'REGISTER_SSO_SUCCESS',
  REGISTER_SSO_FAILED = 'REGISTER_SSO_FAILED',
  CHECK_EXISTING_USER_SSO_REQUEST = 'CHECK_EXISTING_USER_SSO_REQUEST',
  CHECK_EXISTING_USER_SSO_SUCCESS = 'CHECK_EXISTING_USER_SSO_SUCCESS',
  CHECK_EXISTING_USER_SSO_FAILED = 'CHECK_EXISTING_USER_SSO_FAILED',
}

// action
export enum ELoginThaiIdAction {
  LOGIN_THAI_ID_REQUEST = 'LOGIN_THAI_ID_LOGIN_REQUEST',
  LOGIN_THAI_ID_SUCCESS = 'LOGIN_THAI_ID_LOGIN_SUCCESS',
  LOGIN_THAI_ID_FAILED = 'LOGIN_THAI_ID_LOGIN_FAILED',
  LOGIN_THAI_ID_NOT_LOGIN = 'LOGIN_THAI_ID_NOT_LOGIN',
  LOGIN_THAI_ID_ALREADY_LOGIN = 'LOGIN_THAI_ID_ALREADY_LOGIN',
}

export enum ELoginSsoThaiIdAction {
  LOGIN_SSO_THAI_ID_REQUEST = 'LOGIN_SSO_THAI_ID_LOGIN_REQUEST',
  LOGIN_SSO_THAI_ID_SUCCESS = 'LOGIN_SSO_THAI_ID_LOGIN_SUCCESS',
  LOGIN_SSO_THAI_ID_FAILED = 'LOGIN_SSO_THAI_ID_LOGIN_FAILED',
}

export enum EConfigAction {
  GET_CONFIG_REQUEST = 'GET_CONFIG_REQUEST',
  GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS',
  GET_CONFIG_FAILED = 'GET_CONFIG_FAILED',
}

export enum EConfigType {
  WTL01 = 'WTL01',
  WTL02 = 'WTL02',
  WTL03 = 'WTL03',
}

export interface ILogin {
  isPasswordExpired?: boolean;
  userToken?: string;
}

export interface IForgotPassword {
  email?: string;
  token?: string;
}

export interface IAuthenticateState {
  isAuthenticate: boolean;
  isPageLoading: boolean;
  forgetPassword: IForgotPassword;
  error?: Error;
  isValidUserToken?: boolean;
  loginInfo: ILogin;
  userDetailSso: IGetUserDetailResponse;
  isAuthenticateThaiId: boolean;
  thaiIdPID: string;
  SYSconfig: any;
}

export default (state = {}, action) => {
  switch (action.type) {
    case ELoginAction.ALREADY_LOGIN:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: false,
      };
    case ELoginAction.NOT_LOGIN:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
      };
    case ELoginAction.REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case ELoginAction.SUCCESS:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: false,
        loginInfo: action.data,
      };
    case ELoginAction.FAILED:
      return {
        ...state,
        isAuthenticate: false,
        error: action.error,
        isPageLoading: false,
      };
    case ELogoutAction.REQUEST:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: true,
      };
    case ELogoutAction.SUCCESS:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
      };
    case ELogoutAction.FAILED:
      return {
        ...state,
        isPageLoading: false,
      };
    case ESetPassword.REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case ESetPassword.SUCCESS:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
      };
    case ESetPassword.FAILED:
      return {
        ...state,
        isAuthenticate: false,
        error: action.error,
        isPageLoading: false,
      };
    case EWebReport.REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case EWebReport.SUCCESS:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
      };
    case EWebReport.FAILED:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
        error: action.error,
      };
    case EForgotPassword.REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case EForgotPassword.SUCCESS:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
        forgetPassword: action.forgetPassword,
      };
    case EForgotPassword.FAILED:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
        error: action.error,
      };
    case EResetPassword.REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case EResetPassword.SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        isAuthenticate: false,
      };
    case EResetPassword.FAILED:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
        error: action.error,
      };
    case EResetPassword.CLEAR_STORE:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
        forgetPassword: undefined,
        error: undefined,
      };
    case EValidateUserToken.REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case EValidateUserToken.SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        isValidUserToken: action.isValidUserToken,
        error: undefined,
      };
    case EValidateUserToken.FAILED:
      return {
        ...state,
        isPageLoading: false,
        isValidUserToken: false,
        error: action.error,
      };
    //NEW LOGIN
    case ENewLoginAction.EMAIL_REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case ENewLoginAction.EMAIL_SUCCESS:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
        // loginInfo: action.data,
      };
    case ENewLoginAction.EMAIL_FAILED:
      return {
        ...state,
        isAuthenticate: false,
        error: action.error,
        isPageLoading: false,
      };
    case ENewLoginAction.OTP_REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case ENewLoginAction.OTP_SUCCESS:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
        // loginInfo: action.data,
      };
    case ENewLoginAction.OTP_FAILED:
      return {
        ...state,
        isAuthenticate: false,
        error: action.error,
        isPageLoading: false,
      };
    case ENewLoginAction.PASSWORD_REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case ENewLoginAction.PASSWORD_SUCCESS:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: false,
        loginInfo: action.data,
      };
    case ENewLoginAction.PASSWORD_FAILED:
      return {
        ...state,
        isAuthenticate: false,
        error: action.error,
        isPageLoading: false,
      };
    // NOTE: SSO
    // ;; SSO LOGIN
    case ELoginSSOAction.LOGIN_SSO_REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case ELoginSSOAction.LOGIN_SSO_SUCCESS:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: false,
      };
    case ELoginSSOAction.LOGIN_SSO_FAILED:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
        error: action.error,
      };
    // ;; SSO LOGOUT
    case ELoginSSOAction.LOGOUT_SSO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ELoginSSOAction.LOGOUT_SSO_SUCCESS:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
      };
    case ELoginSSOAction.LOGOUT_SSO_FAILED:
      return {
        ...state,
        isPageLoading: false,
      };
    // ;; SSO USER DETAIL
    case ELoginSSOAction.USER_DETAIL_SSO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ELoginSSOAction.USER_DETAIL_SSO_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        userDetailSso: action.data,
      };
    case ELoginSSOAction.USER_DETAIL_SSO_FAILED:
      return {
        ...state,
        isPageLoading: false,
        error: action.error,
      };
    // ;; SSO GET SERVICE ACCESS TOKEN
    case ELoginSSOAction.GET_SERVICE_ACCESS_TOKEN_SSO_REQUEST:
      return {
        ...state,
        // isAuthenticate: false,
        isPageLoading: true,
      };
    case ELoginSSOAction.GET_SERVICE_ACCESS_TOKEN_SSO_SUCCESS:
      return {
        ...state,
        // isAuthenticate: true,
        isPageLoading: false,
      };
    case ELoginSSOAction.GET_SERVICE_ACCESS_TOKEN_SSO_FAILED:
      return {
        ...state,
        // isAuthenticate: false,
        isPageLoading: false,
        error: action.error,
      };
    // ;; SSO CHANGE PASSWORD
    case ELoginSSOAction.CHANGE_PASSWORD_SSO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ELoginSSOAction.CHANGE_PASSWORD_SSO_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case ELoginSSOAction.CHANGE_PASSWORD_SSO_FAILED:
      return {
        ...state,
        isPageLoading: false,
        error: action.error,
      };
    // ;; SSO FORGET PASSWORD
    case ELoginSSOAction.FORGOT_PASSWORD_SSO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ELoginSSOAction.FORGOT_PASSWORD_SSO_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case ELoginSSOAction.FORGOT_PASSWORD_SSO_FAILED:
      return {
        ...state,
        isPageLoading: false,
        error: action.error,
      };
    // ;; SSO RESET PASSWORD
    case ELoginSSOAction.RESET_PASSWORD_SSO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ELoginSSOAction.RESET_PASSWORD_SSO_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case ELoginSSOAction.RESET_PASSWORD_SSO_FAILED:
      return {
        ...state,
        isPageLoading: false,
        error: action.error,
      };
    // ;; SSO REGISTER
    case ELoginSSOAction.REGISTER_SSO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ELoginSSOAction.REGISTER_SSO_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case ELoginSSOAction.REGISTER_SSO_FAILED:
      return {
        ...state,
        isPageLoading: false,
        error: action.error,
      };
    // ;; SSO CHECK USER
    case ELoginSSOAction.CHECK_EXISTING_USER_SSO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case ELoginSSOAction.CHECK_EXISTING_USER_SSO_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case ELoginSSOAction.CHECK_EXISTING_USER_SSO_FAILED:
      return {
        ...state,
        isPageLoading: false,
        error: action.error,
      };

    // ;; AUTH THAI ID
    case ELoginThaiIdAction.LOGIN_THAI_ID_ALREADY_LOGIN:
      return {
        ...state,
        isPageLoading: false,
        isAuthenticateThaiId: true,
        isAuthenticate: true,
      };
    case ELoginThaiIdAction.LOGIN_THAI_ID_NOT_LOGIN:
      return {
        ...state,
        isPageLoading: false,
        isAuthenticateThaiId: false,
        isAuthenticate: false,
      };
    case ELoginThaiIdAction.LOGIN_THAI_ID_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isAuthenticateThaiId: false,
        isAuthenticate: false,
      };
    case ELoginThaiIdAction.LOGIN_THAI_ID_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        isAuthenticateThaiId: true,
        isAuthenticate: true,
      };
    case ELoginThaiIdAction.LOGIN_THAI_ID_FAILED:
      return {
        ...state,
        isPageLoading: false,
        error: action.error,
        isAuthenticateThaiId: false,
        isAuthenticate: false,
      };
    // ;; AUTH SSO THAI ID
    case ELoginSsoThaiIdAction.LOGIN_SSO_THAI_ID_REQUEST:
      return {
        ...state,
        isPageLoading: true,
        isAuthenticateThaiId: false,
        isAuthenticate: false,
      };
    case ELoginSsoThaiIdAction.LOGIN_SSO_THAI_ID_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        isAuthenticateThaiId: true,
        isAuthenticate: true,
      };
    case ELoginSsoThaiIdAction.LOGIN_SSO_THAI_ID_FAILED:
      return {
        ...state,
        isPageLoading: false,
        error: action.error,
        isAuthenticateThaiId: false,
        isAuthenticate: false,
      };
    // ;; CONFIG
    case EConfigAction.GET_CONFIG_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EConfigAction.GET_CONFIG_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
        SYSconfig: action.data,
      };
    case EConfigAction.GET_CONFIG_FAILED:
      return {
        ...state,
        isPageLoading: false,
        error: action.error,
      };
    // NOTE: DEFUALT
    default:
      return state;
  }
};

export const verifyToken = (cb?: Function) => {
  return (dispatch) => {
    const token = localStorage.getItem(LocalStorageKey.TOKEN);
    const expires = localStorage.getItem(LocalStorageKey.EXPIRES);
    if (token && expires && new Date(expires) > new Date()) {
      dispatch(alreadyLogin());
    } else {
      if (expires && new Date(expires) <= new Date()) {
        StatusAlertWithHeader('ลิงค์หมดอายุ', 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง', AlertStatus.Error).then(() =>
          dispatch(notLogin(cb))
        );
      } else {
        dispatch(notLogin(cb));
      }
    }
  };
};

export const alreadyLogin = () => {
  return {
    type: ELoginAction.ALREADY_LOGIN,
  };
};

export const notLogin = (cb?: Function) => {
  if (cb) cb();
  localStorage.removeItem(LocalStorageKey.TOKEN);
  localStorage.removeItem(LocalStorageKey.EXPIRES);
  return {
    type: ELoginAction.NOT_LOGIN,
  };
};

export const login = (data, cb?: Function) => {
  if (!data.email || !data.password) {
    return (dispatch) => {
      dispatch(loginFailed(i18next.t('auth:login_page.form.message.failed')));
    };
  }
  return (dispatch) => {
    dispatch({ type: ELoginAction.REQUEST });
    axios
      .post('/auth/getUserLogin', JSON.stringify(data))
      .then((response) => {
        dispatch(loginSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(loginFailed(error));
      });
  };
};

export const loginSuccess = (data, cb?: Function) => {
  reactLocalStorage.clear();
  localStorage.setItem(LocalStorageKey.TOKEN, data.access_token);
  localStorage.setItem(LocalStorageKey.EXPIRES, data.expires);
  window.location.reload();
  if (cb) cb();
  return {
    type: ELoginAction.SUCCESS,
    data,
  };
};

export const loginFailed = (error) => {
  localStorage.removeItem(LocalStorageKey.TOKEN);
  localStorage.removeItem(LocalStorageKey.EXPIRES);
  StatusAlertWithHeader(i18next.t('main:alert.failed'), error.response?.data, AlertStatus.Error);
  return {
    type: ELoginAction.FAILED,
    error,
  };
};

export const logout = (cb?: Function) => {
  localStorage.clear();
  sessionStorage.clear();
  return (dispatch) => {
    dispatch({ type: ELogoutAction.REQUEST });
    dispatch(logoutSuccess(cb));
    // if (localStorage.getItem(LocalStorageKey.TOKEN)) {
    //   dispatch(logoutSuccess(cb));
    // } else {
    //   dispatch(logoutFailed());
    // }
  };
};

export const logoutSuccess = (cb?: Function) => {
  // eslint-disable-next-line no-console
  window.location.reload();
  if (cb) cb();
  return {
    type: ELogoutAction.SUCCESS,
  };
};

export const logoutFailed = () => {
  return {
    type: ELogoutAction.FAILED,
  };
};

export const setPassword = (token: string, data, cb?: Function) => {
  data.userToken = token;
  return (dispatch) => {
    dispatch({ type: ESetPassword.REQUEST });
    axios
      .post('auth/setPassword', JSON.stringify(data))
      .then((response) => {
        dispatch(setPasswordSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(setPasswordFailed(error));
      });
  };
};

export const setPasswordSuccess = (data, cb?: Function) => {
  StatusAlert(i18next.t('main:alert.success.save'), AlertStatus.Success).then(() => {
    if (cb) cb();
  });
  return {
    type: ESetPassword.SUCCESS,
    data,
  };
};

export const setPasswordFailed = (error) => {
  StatusAlertWithHeader(i18next.t('main:alert.failed'), error.response?.data, AlertStatus.Error);
  return {
    type: ESetPassword.FAILED,
    error,
  };
};

export const webReport = (data, cb?: Function) => {
  return (dispatch) => {
    dispatch({ type: EWebReport.REQUEST });
    axios
      .post('/webReport', JSON.stringify(data))
      .then((response) => {
        dispatch(webReportSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(webReportFailed(error));
      });
  };
};

export const webReportSuccess = (data, cb?: Function) => {
  StatusAlert(i18next.t('main:alert.success.save'), AlertStatus.Success).then(() => {
    if (cb) cb();
  });
  return {
    type: EWebReport.SUCCESS,
    data,
  };
};

export const webReportFailed = (error) => {
  StatusAlertWithHeader(i18next.t('main:alert.failed'), error.response?.data, AlertStatus.Error);
  return {
    type: EWebReport.FAILED,
    error,
  };
};

export const forgotPassword = (email: string, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: EForgotPassword.REQUEST });
    await axios
      .post('/auth/forgotPassword', JSON.stringify({ email }))
      .then((response) => {
        dispatch(forgotPasswordSuccess({ email, token: response.data.token }, response.data, cb));
      })
      .catch((error) => {
        dispatch(forgotPasswordFailed(error));
      });
  };
};

export const forgotPasswordSuccess = (forgetPassword: IForgotPassword, data: any, cb?: Function) => {
  if (cb) cb(data);

  return {
    type: EForgotPassword.SUCCESS,
    forgetPassword,
  };
};

export const forgotPasswordFailed = (error) => {
  return {
    type: EForgotPassword.FAILED,
    error,
  };
};

export const resetPassword = (data, token, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: EResetPassword.REQUEST });
    data['userToken'] = token;
    await axios
      .post('/auth/resetPassword', JSON.stringify(data))
      .then((_response) => {
        dispatch(resetPasswordSuccess(cb));
      })
      .catch((error) => {
        dispatch(resetPaswordFailed(error));
      });
  };
};

export const resetPasswordSuccess = (cb?: Function) => {
  StatusAlert(i18next.t('main:alert.reset_password_success.save'), AlertStatus.Success).then(() => {
    if (cb) cb();
  });
  return {
    type: EResetPassword.SUCCESS,
  };
};

export const resetPaswordFailed = (error) => {
  StatusAlertWithHeader(i18next.t('main:alert.failed'), error.response?.data, AlertStatus.Error);
  return {
    type: EResetPassword.FAILED,
    error,
  };
};

export const clearStore = () => {
  return {
    type: EResetPassword.CLEAR_STORE,
  };
};

export const validateUserToken = (userToken: string, cb?: Function) => {
  return async (dispatch: (arg0: { type: EValidateUserToken; isValidUserToken?: boolean; error?: Error }) => void) => {
    dispatch({ type: EValidateUserToken.REQUEST });
    try {
      const response = await axios.post('/auth/isValidToken', { userToken });
      dispatch(validateUserTokenSuccess(response.data.isValid, cb));
    } catch (error) {
      dispatch(validateUserTokenFailed(error));
    }
  };
};

const validateUserTokenSuccess = (isValidUserToken: boolean, cb?: Function) => {
  if (cb) cb();
  return {
    type: EValidateUserToken.SUCCESS,
    isValidUserToken,
  };
};

const validateUserTokenFailed = (error) => {
  return {
    type: EValidateUserToken.FAILED,
    error,
  };
};

// NEW LOGIN
// LOGIN EMAIL
export const loginEmail = (data, cb?: Function) => {
  // if (!data.email) {
  //   return (dispatch) => {
  //     dispatch(loginEmailFailed(i18next.t('auth:login_page.form.message.failed')));
  //   };
  // }
  return (dispatch) => {
    dispatch({ type: ENewLoginAction.EMAIL_REQUEST });
    axios
      .post('/auth/getUserLogin', JSON.stringify(data))
      .then((response) => {
        dispatch(loginEmailSuccess(response.data, cb));
      })
      .catch((error) => {
        // console.log(error.response.status)
        dispatch(loginEmailFailed(error.response, cb));
      });
  };
};

export const loginEmailSuccess = (data, cb?: Function) => {
  if (cb) cb(data);
  return {
    type: ENewLoginAction.EMAIL_SUCCESS,
  };
};

export const loginEmailFailed = (error, cb?: Function) => {
  if (cb) cb(error);
  // StatusAlertWithHeader(i18next.t('main:alert.failed'), error.response?.data, AlertStatus.Error);
  return {
    type: ENewLoginAction.EMAIL_FAILED,
    error,
  };
};

// SEND OTP
export const loginOtp = (data, cb?: Function) => {
  if (!data.otp) {
    return (dispatch) => {
      dispatch(loginOtpFailed(i18next.t('auth:login_page.form.message.failed')));
    };
  }
  return (dispatch) => {
    dispatch({ type: ENewLoginAction.OTP_REQUEST });
    axios
      .post('/auth/phoneNumberAuthenticate', JSON.stringify(data))
      .then((response) => {
        dispatch(loginOtpSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(loginOtpFailed(error));
      });
  };
};

export const loginOtpSuccess = (data, cb?: Function) => {
  if (cb) cb(data);
  return {
    type: ENewLoginAction.OTP_SUCCESS,
  };
};

export const loginOtpFailed = (error) => {
  StatusAlertWithHeader(i18next.t('main:alert.failed'), error.response?.data, AlertStatus.Error);
  return {
    type: ENewLoginAction.OTP_FAILED,
    error,
  };
};

// LOGIN PASSWORD
export const loginPassword = (data, cb?: Function) => {
  if (!data.password) {
    return (dispatch) => {
      dispatch(loginFailed(i18next.t('auth:login_page.form.message.failed')));
    };
  }
  return (dispatch) => {
    dispatch({ type: ENewLoginAction.PASSWORD_REQUEST });
    axios
      .post('/auth/getPasswordLogin ', JSON.stringify(data))
      .then((response) => {
        dispatch(loginSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(loginFailed(error));
      });
  };
};

export const loginPasswordSuccess = (data, cb?: Function) => {
  localStorage.setItem(LocalStorageKey.TOKEN, data.access_token);
  localStorage.setItem(LocalStorageKey.EXPIRES, data.expires);
  if (cb) cb();
  return {
    type: ENewLoginAction.PASSWORD_SUCCESS,
    data,
  };
};

export const loginPasswordFailed = (error) => {
  localStorage.removeItem(LocalStorageKey.TOKEN);
  localStorage.removeItem(LocalStorageKey.EXPIRES);
  StatusAlertWithHeader(i18next.t('main:alert.failed'), error.response?.data, AlertStatus.Error);
  return {
    type: ENewLoginAction.PASSWORD_FAILED,
    error,
  };
};

// NOTE: SSO
// ;; LOGIN SSO
export const loginSso = (data, cb?: Function) => {
  const params: ILoginSsoRequest = {
    username: data.email?.replaceAll('-', ''),
    password: data.password,
    serviceId: data.serviceId,
  };
  return (dispatch) => {
    dispatch({ type: ELoginSSOAction.LOGIN_SSO_REQUEST });
    axios
      .post('/SSOAuthen/GetLogin', JSON.stringify(params))
      .then((response) => {
        dispatch(loginSsoSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(loginSsoFailed(error));
      });
  };
};

export const loginSsoSuccess = (data, cb?: Function) => {
  // localStorage.setItem(LocalStorageKey.TOKEN, data.access_token);
  localStorage.setItem(
    LocalStorageKey.TOKEN,
    data?.serviceResponse?.serviceAccessToken.access_token || data.access_token
  );
  localStorage.setItem(LocalStorageKey.EXPIRES, data.expires);
  localStorage.setItem(LocalStorageKey.TOKEN_SSO, data.access_token);
  if (cb) cb(data);
  return {
    type: ELoginSSOAction.LOGIN_SSO_SUCCESS,
  };
};

export const loginSsoFailed = (error) => {
  localStorage.removeItem(LocalStorageKey.TOKEN);
  localStorage.removeItem(LocalStorageKey.EXPIRES);
  localStorage.removeItem(LocalStorageKey.TOKEN_SSO);
  StatusAlertWithHeader(i18next.t('main:alert.failed'), error.response?.data, AlertStatus.Error);
  return {
    type: ELoginSSOAction.LOGIN_SSO_FAILED,
    error,
  };
};

// ;; LOGOUT SSO
export const logoutSso = (cb?: Function) => {
  return (dispatch) => {
    dispatch({ type: ELoginSSOAction.LOGIN_SSO_REQUEST });
    axios
      .get('/SSOAuthen/GetLogout', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        dispatch(logoutSsoSuccess(cb));
      })
      .catch((error) => {
        dispatch(logoutSsoFailed(error));
      });
  };
};

export const logoutSsoSuccess = (cb?: Function) => {
  reactLocalStorage.clear();
  window.location.reload();
  if (cb) cb();
  return {
    type: ELoginSSOAction.LOGIN_SSO_SUCCESS,
  };
};

export const logoutSsoFailed = (error) => {
  return {
    type: ELoginSSOAction.LOGIN_SSO_FAILED,
    error,
  };
};

// ;; USER DETAIL SSO
export const getUserDetailSso = (cb?: Function) => {
  return (dispatch) => {
    dispatch({ type: ELoginSSOAction.USER_DETAIL_SSO_REQUEST });
    axios
      .get('/SSOAuthen/UserDetail', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        dispatch(getUserDetailSsoSuccess(response.data.data, cb));
      })
      .catch((error) => {
        dispatch(getUserDetailSsoFailed(error));
      });
  };
};

export const getUserDetailSsoSuccess = (data: IGetUserDetailResponse, cb?: Function) => {
  if (cb) cb(data);
  return {
    type: ELoginSSOAction.USER_DETAIL_SSO_SUCCESS,
    data,
  };
};

export const getUserDetailSsoFailed = (error) => {
  return {
    type: ELoginSSOAction.USER_DETAIL_SSO_FAILED,
    error,
  };
};

// ;; GET SERVICE ACCESS TOKEN SSO
export const onGetServiceAccessToken = (serviceId: string, cb?: Function) => {
  const body: IGetServiceAccessTokenRequest = {
    serviceID: serviceId,
  };
  const params = toQueryString(body);
  return (dispatch) => {
    dispatch({ type: ELoginSSOAction.GET_SERVICE_ACCESS_TOKEN_SSO_REQUEST });
    axios
      .post('/SSOAuthen/GetServiceAccessToken', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token-sso')}`,
        },
      })
      .then((response) => {
        dispatch(onGetServiceAccessTokenSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(onGetServiceAccessTokenFailed(error));
      });
  };
};

export const onGetServiceAccessTokenSuccess = (data, cb?: Function) => {
  localStorage.setItem(LocalStorageKey.TOKEN, data?.serviceAccessToken.access_token || '');
  if (cb) cb();
  return {
    type: ELoginSSOAction.GET_SERVICE_ACCESS_TOKEN_SSO_SUCCESS,
  };
};

export const onGetServiceAccessTokenFailed = (error) => {
  return {
    type: ELoginSSOAction.GET_SERVICE_ACCESS_TOKEN_SSO_FAILED,
    error,
  };
};

// ;; CHANGE PASSWORD SSO
export const onChangePassword = (data: any, cb?: Function) => {
  const body: IChangePasswordSsoRequest = {
    oldPassword: '',
    newPassword: '',
  };
  const params = toQueryString(body);
  return (dispatch) => {
    dispatch({ type: ELoginSSOAction.CHANGE_PASSWORD_SSO_REQUEST });
    axios
      .post('/SSOAuthen/ChangePassword', params)
      .then((response) => {
        dispatch(onChangePasswordSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(onChangePasswordFailed(error));
      });
  };
};

export const onChangePasswordSuccess = (data: any, cb?: Function) => {
  if (cb) cb();
  return {
    type: ELoginSSOAction.CHANGE_PASSWORD_SSO_SUCCESS,
  };
};

export const onChangePasswordFailed = (error) => {
  return {
    type: ELoginSSOAction.CHANGE_PASSWORD_SSO_FAILED,
    error,
  };
};

// ;; FORGET PASSWORD SSO
export const onForgotPassword = (data: IForgotPasswordForm, cb?: Function) => {
  const body: IForgotPasswordSsoRequest = {
    username: data.username,
  };
  const params = toQueryString(body);
  return (dispatch) => {
    dispatch({ type: ELoginSSOAction.FORGOT_PASSWORD_SSO_REQUEST });
    axios
      .post('/SSOAuthen/ForgotPassword', params)
      .then((response) => {
        dispatch(onForgotPasswordSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(onForgotPasswordFailed(error));
      });
  };
};

export const onForgotPasswordSuccess = (data: IForgotPasswordSsoResponse, cb?: Function) => {
  if (cb) cb(data);
  return {
    type: ELoginSSOAction.FORGOT_PASSWORD_SSO_SUCCESS,
  };
};

export const onForgotPasswordFailed = (error) => {
  return {
    type: ELoginSSOAction.FORGOT_PASSWORD_SSO_FAILED,
    error,
  };
};

// ;; RESET PASSWORD SSO
export const onResetPassword = (data: IResetPasswordForm, cb?: Function) => {
  const body: IResetPasswordSsoRequest = {
    otp: data.otp,
    referenceCode: data.referenceCode,
    newPassword: data.newPassword,
  };
  const params = toQueryString(body);
  return (dispatch) => {
    dispatch({ type: ELoginSSOAction.RESET_PASSWORD_SSO_REQUEST });
    axios
      .post('/SSOAuthen/ResetPassword', params)
      .then((response) => {
        dispatch(onResetPasswordSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(onResetPasswordFailed(error));
      });
  };
};

export const onResetPasswordSuccess = (data: any, cb?: Function) => {
  if (cb) cb();
  return {
    type: ELoginSSOAction.RESET_PASSWORD_SSO_SUCCESS,
  };
};

export const onResetPasswordFailed = (error) => {
  return {
    type: ELoginSSOAction.RESET_PASSWORD_SSO_FAILED,
    error,
  };
};

// ;; REGISTER SSO
export const onRegisterSso = (data: IRegisterForm, cb?: Function) => {
  const body: IRegisterSsoRequest = {
    idCard: data.idCard,
    phoneNumber: data.phoneNumber,
  };
  const params = toQueryString(body);
  return (dispatch) => {
    dispatch({ type: ELoginSSOAction.REGISTER_SSO_REQUEST });
    axios
      .post('/SSOAuthen/Register', params)
      .then((response) => {
        dispatch(onRegisterSsoSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(onRegisterSsoFailed(error));
      });
  };
};

export const onRegisterSsoSuccess = (data: any, cb?: Function) => {
  if (cb) cb();
  return {
    type: ELoginSSOAction.REGISTER_SSO_SUCCESS,
  };
};

export const onRegisterSsoFailed = (error) => {
  return {
    type: ELoginSSOAction.REGISTER_SSO_FAILED,
    error,
  };
};

// ;; CHECK EXIST SSO
export const onCheckExistingUser = (data: IRegisterForm, cbSuccess: Function, cbFailed: Function) => {
  const body: ICheckExistingUserSsoRequest = {
    username: data.username,
    idCard: data.idCard,
  };
  const params = toQueryString(body);
  return (dispatch) => {
    dispatch({ type: ELoginSSOAction.CHECK_EXISTING_USER_SSO_REQUEST });
    axios
      .post('/SSOAuthen/CheckExistingUser', params)
      .then((response) => {
        dispatch(onCheckExistingUserSuccess(response.data, cbSuccess));
      })
      .catch((error) => {
        dispatch(onCheckExistingUserFailed(error, cbFailed));
      });
  };
};

export const onCheckExistingUserSuccess = (data: ICheckExistingUserSsoResponse, cb?: Function) => {
  if (cb) cb(data);
  return {
    type: ELoginSSOAction.CHECK_EXISTING_USER_SSO_SUCCESS,
  };
};

export const onCheckExistingUserFailed = (error, cb?: Function) => {
  if (cb) cb();
  return {
    type: ELoginSSOAction.CHECK_EXISTING_USER_SSO_FAILED,
    error,
  };
};
// ;; LOGIN THAI ID
export const verifyTokenThaiId = (cb?: Function) => {
  return (dispatch) => {
    axios
      .post(
        '/Linkage/GetThaiIdStatus',
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((response) => {
        const is = response?.data?.thaiIdStatus;
        if (!!is) dispatch(alreadyLoginThaiId());
        else dispatch(notLoginThaiId());
      })
      .catch((error) => {
        dispatch(notLoginThaiId());
      });
  };
};

export const alreadyLoginThaiId = () => {
  return {
    type: ELoginThaiIdAction.LOGIN_THAI_ID_ALREADY_LOGIN,
  };
};

export const notLoginThaiId = () => {
  // localStorage.removeItem(GetThaiPid.THAI_ID_PID);

  return {
    type: ELoginThaiIdAction.LOGIN_THAI_ID_NOT_LOGIN,
  };
};

export const loginThaiId = (data: any, cb?: Function) => {
  // return (dispatch) => {
  //   dispatch(
  //     loginThaiIdSuccess(
  //       {
  //         access_token:
  //           'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0WUpIWlZtQmNxazEzdnMzNzI0M1lXZG9TMG5WSmdDaEMwZjNCVW9pRi1VUm1rQk9KMFBWbXciLCJqdGkiOiI2OWMzYWFiYy0xNGJjLTQ0NzgtYTI5Yi1hNTViMTU4MGM0MjQiLCJleHAiOjE3MjYwNDIwMDcsImlzcyI6IkdPIFZlbnR1cmUgRGV2ZWxvcG1lbnQiLCJhdWQiOiJVQ0kifQ.uEQ3afB36JL08QYwijV7H9bBocxDEt4MyzlFsoXRnW0',
  //         expires: 'Wed, 11 Sep 2024 15:06:47 GMT',
  //       },
  //       'QQQQQ'
  //     )
  //   );
  // };
  if (!data.code) {
    return (dispatch) => {
      dispatch(loginThaiIdFailed(i18next.t('auth:login_page.form.message.failed')));
    };
  }
  return (dispatch) => {
    dispatch({ type: ELoginThaiIdAction.LOGIN_THAI_ID_REQUEST });
    const dto = {
      thaiIdCode: data.code,
    };
    axios
      .post('/Linkage/LoginWithThaiId', dto, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        dispatch(loginThaiIdSuccess(response.data, data.code, cb));
      })
      .catch((error) => {
        dispatch(loginThaiIdFailed(error));
      });
  };
};

export const loginThaiIdSuccess = (data, code: string, cb?: Function) => {
  localStorage.clear();
  // sessionStorage.setItem(ELinkageNSB.OTA, ELinkageNSB.OTA);
  // localStorage.setItem(LocalStorageKey.THAI_ID_CODE, code);
  localStorage.setItem(LocalStorageKey.TOKEN, data.access_token);
  localStorage.setItem(LocalStorageKey.EXPIRES, data.expires);
  window.location.reload();
  if (cb) cb();
  return {
    type: ELoginThaiIdAction.LOGIN_THAI_ID_SUCCESS,
    data,
  };
};

export const loginThaiIdFailed = (error) => {
  localStorage.removeItem(LocalStorageKey.THAI_ID_CODE);
  //Test
  localStorage.removeItem(GetThaiPid.THAI_ID_PID);

  const message = 'ตรวจสอบสิทธิ์เข้าใช้งานระบบอีกครั้ง หากยังไม่สามารถล็อคอินได้ กรุณาติดต่อเจ้าหน้าที่';
  StatusAlertWithHeader(i18next.t('main:alert.failed'), message, AlertStatus.Error).then(() => {
    //window.location.pathname = '/login';
    window.location.pathname = '/QRCODEREDIRECT';
  });
  return {
    type: ELoginThaiIdAction.LOGIN_THAI_ID_FAILED,
    error,
  };
};

// ;; LOGIN SSO THAI ID
export const loginSsoThaiId = (data: any, cb?: Function) => {
  // DEBUG
  // return (dispatch) => {
  //   dispatch(
  //     loginSsoThaiIdSuccess({
  //       access_token:
  //         'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI4NWZlMjdJNU5QZnpFWEg0dkJIOS0tRk5JbmdDc0FsX0xEdkJCV015MXphZlg5cnRIRXFOZlEiLCJqdGkiOiI3ZjljZDE2OC0wNmM4LTRkY2ItOTg3NC1mMzA3MjUwYWUwNjciLCJleHAiOjE3MjY3MTY0MTAsImlzcyI6IkdPIFZlbnR1cmUgRGV2ZWxvcG1lbnQiLCJhdWQiOiJVQ0kifQ.vbcQhVbbFjOLYRen7CtNDkOCsh-ob8Zry8N4M6r2ibE',
  //       expires: 'Thu, 19 Sep 2024 10:26:50 GMT',
  //     })
  //   );
  // };
  // DEBUG END
  if (!data.code) {
    return (dispatch) => {
      dispatch(loginSsoThaiIdFailed(i18next.t('auth:login_page.form.message.failed')));
    };
  }
  return (dispatch) => {
    dispatch({ type: ELoginSsoThaiIdAction.LOGIN_SSO_THAI_ID_REQUEST });
    const dto = {
      thaiIdCode: data.code,
    };

    axios
      .post('/Auth/GetThaiIdLogin', dto)
      // .post('/SSOAuthen/GetThaiIdLogin', dto)
      .then((response) => {
        dispatch(loginSsoThaiIdSuccess(response.data, cb));
      })
      .catch((error) => {
        dispatch(loginSsoThaiIdFailed(error));
      });
  };
};

export const loginSsoThaiIdSuccess = (data, cb?: Function) => {
  localStorage.clear();
  localStorage.setItem(LocalStorageKey.TOKEN, data.access_token);
  // localStorage.setItem(LocalStorageKey.EXPIRES, data.expires);

  const currentDate = new Date();

  // Add one hour to the current date
  currentDate.setHours(currentDate.getHours() + 1);
  localStorage.setItem(LocalStorageKey.EXPIRES, currentDate.toString());
  window.location.reload();
  if (cb) cb();
  return {
    type: ELoginSsoThaiIdAction.LOGIN_SSO_THAI_ID_SUCCESS,
    data,
  };
};

export const loginSsoThaiIdFailed = (error) => {
  const message = 'ตรวจสอบสิทธิ์เข้าใช้งานระบบอีกครั้ง หากยังไม่สามารถล็อคอินได้ กรุณาติดต่อเจ้าหน้าที่';
  StatusAlertWithHeader(i18next.t('main:alert.failed'), message, AlertStatus.Error).then(() => {
    window.location.pathname = '/QRCODEREDIRECT';
  });
  return {
    type: ELoginSsoThaiIdAction.LOGIN_SSO_THAI_ID_FAILED,
    error,
  };
};

// ;; CONFIG
export const getConfig = (type: string, cb?: Function) => {
  return (dispatch) => {
    dispatch({ type: EConfigAction.GET_CONFIG_REQUEST });
    axios
      .get('/SystemConfig/GetBeforeLoginConfig')
      .then((response) => {
        var a = response.data.filter((o) => o['systemConfigName'] === type)[0];
        dispatch(getConfigSuccess(a, cb));
      })
      .catch((error) => {
        dispatch(getConfigFailed(error));
      });
  };
};

export const getConfigSuccess = (data, cb?: Function) => {
  if (cb) cb();
  return {
    type: EConfigAction.GET_CONFIG_SUCCESS,
    data,
  };
};

export const getConfigFailed = (error) => {
  return {
    type: EConfigAction.GET_CONFIG_FAILED,
    error,
  };
};
