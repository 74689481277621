import { UnAuthenticatedHeader, UnAuthenticatedHeaderINC } from 'components/layout';
import Footer from 'components/layout/Footer';
import React, { FC, lazy, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import config from 'shared/config/app.env';
import { IReducer } from 'store';
import { EConfigType, getConfig, LocalStorageKey, verifyToken, verifyTokenThaiId } from 'store/authenticate';

const LoginSsoPage = lazy(() => import('pages/Auth/Sso/Login'));
// eslint-disable-next-line
const Alternative = lazy(() => import('pages/Alternative'));
const LoginPage = lazy(() => import('pages/Auth/Login'));
const RegisterPage = lazy(() => import('pages/Auth/Register/Form'));
const CheckStatusPage = lazy(() => import('pages/Auth/CheckStatus'));
const OtpPage = lazy(() => import('pages/Auth/Otp'));
const PasswordPage = lazy(() => import('pages/Auth/Password'));
const SetPasswordPage = lazy(() => import('pages/Auth/SetPassword'));
const SetPasswordPageOtp = lazy(() => import('pages/Auth/SetPasswordByOtp'));
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/Auth/ForgotPassword/ResetPassword'));
const WebReport = lazy(() => import('pages/Auth/WebReport'));
const AuthenticatedRouter = lazy(() => import('./Authenticated.router'));
const SSOPage = lazy(() => import('./SSO.router'));
const SsoRegisterPage = lazy(() => import('pages/Auth/Sso/Register'));
const SsoForgotPasswordPage = lazy(() => import('pages/Auth/Sso/ForgotPassword'));
const SsoResetPasswordPage = lazy(() => import('pages/Auth/Sso/ResetPassword'));

const QRCODE = lazy(() => import('pages/QRCODE'));
const GETCODE = lazy(() => import('pages/QRCODE/components/GETCODE'));
const QRCODEREDIRECT = lazy(() => import('pages/QRCODE/components/QRCODEREDIRECT'));
const INC = lazy(() => import('pages/INC'));

const AppRouter: FC = () => {
  const { t } = useTranslation('main');
  const dispatch = useDispatch();
  const isAuthenticate = useSelector((store: IReducer) => store.authenticate.isAuthenticate);
  const isAuthenticateThaiId = useSelector((store: IReducer) => store.authenticate.isAuthenticateThaiId);
  const SYSconfig = useSelector((store: IReducer) => store.authenticate.SYSconfig);
  // console.log('🚀 : SYSconfig:', SYSconfig);

  useEffect(() => {
    dispatch(verifyToken());
    dispatch(getConfig('web_login_type'));
  }, [dispatch]);

  const checkExpireToken = (): boolean => {
    const expire = localStorage.getItem(LocalStorageKey.EXPIRES);

    // const expireThaiId = localStorage.getItem(LocalStorageKey.THAI_ID_CODE);
    const expireDate = expire ? new Date(expire) : null;
    // Get the current date and time
    // const currentDate = new Date();

    // // Add one hour to the current date
    // currentDate.setHours(currentDate.getHours() + 1);
    // const expireDateTest = expire ? new Date(currentDate) : null;

    // console.log('Current date + 1 hour:', expireDateTest);

    // if (expireThaiId) return true;
    if (expireDate && expireDate > new Date()) return true;
    else {
      dispatch(verifyToken());
      return false;
    }
  };

  // ;; check thai id
  React.useEffect(() => {
    // if (checkExpireToken()) dispatch(verifyTokenThaiId());
  }, [dispatch]);

  return (
    <>
      {!isAuthenticate ? (
        SYSconfig?.['systemConfigValue'] != EConfigType.WTL01 ? (
          <UnAuthenticatedHeader />
        ) : (
          // <UnAuthenticatedHeaderINC />

          <></>
        )
      ) : (
        <div />
      )}
      <Suspense fallback={<div>{t('router.lazy_loading.label')}</div>}>
        <Switch>
          {/* NOTE: INC */}
          <Route exact path={checkExpireToken() ? '/INC' : '/'} component={INC} />
          {/* NOTE: SSO-LINKAGE */}
          <Route exact path="/GETCODE" component={GETCODE} />
          <Route
            exact
            path="/QRCODEREDIRECT"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <QRCODEREDIRECT />;
            }}
          />
          <Route
            exact
            path="/QRCODE"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <QRCODE />;
            }}
          />
          {/* NOTE: DEFAULT */}
          <Route
            exact
            path="/login"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              // else return <LoginPage />;
              else {
                if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL01) return <Redirect to="/QRCODEREDIRECT" />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL02) return <Redirect to="/login-sso" />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL03) return <LoginPage />;
              }
            }}
          />
          {/* <Route
            exact
            path="/login-sso"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <LoginSsoPage />;
            }}
          /> */}
          <Route
            exact
            path="/register"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <RegisterPage />;
            }}
          />
          <Route
            exact
            path="/checkStatus"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <CheckStatusPage />;
            }}
          />
          <Route
            exact
            path="/otp/:pheonNo/:userToken"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <OtpPage />;
            }}
          />
          <Route
            exact
            path="/password/:email/:userToken"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <PasswordPage />;
            }}
          />
          <Route
            exact
            path="/set-password/:userToken"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <SetPasswordPageOtp />;
            }}
          />
          <Route
            exact
            path="/forgot-password"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <ForgotPassword />;
            }}
          />
          <Route
            exact
            path="/forgot-password/:email"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <ResetPassword />;
            }}
          />
          <Route exact path="/set-password" component={SetPasswordPage} />
          <Route
            exact
            path="/web-report"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <WebReport />;
            }}
          />
          {/* NOTE: SSO */}
          <Route exact path="/sso" component={SSOPage} />
          <Route
            exact
            path="/login-sso"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else {
                if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL01) return <Redirect to="/QRCODEREDIRECT" />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL02) return <LoginSsoPage />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL03) return <Redirect to="/login" />;
              }
              /*
                return SYSconfig?.['systemConfigValue'] === EConfigType.WTL02 ? (
                  <Redirect to="/QRCODEREDIRECT" />
                ) : (
                  <LoginSsoPage />
                );
                */
            }}
          />
          <Route
            exact
            path="/login-sso/register"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <SsoRegisterPage />;
            }}
          />
          <Route
            exact
            path="/login-sso/forgot-password"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <SsoForgotPasswordPage />;
            }}
          />
          <Route
            exact
            path="/login-sso/reset-password"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <SsoResetPasswordPage />;
            }}
          />
          {/* NOTE: DEFAULT */}
          {/* 
          <Route
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate && checkExpireToken()) return <AuthenticatedRouter />;
              else return config.SSO ? <Redirect to="/login-sso" /> : <Redirect to="/login" />;
            }}
          />
          */}
          {/* NOTE: DEFAULT */}
          <Route
            render={() => {
              if (isAuthenticate === undefined) {
                dispatch(getConfig('web_login_type'));
                return dispatch(verifyToken());
                // } else if (isAuthenticate && checkExpireToken()) return <Redirect to="/linkage" />;
              } else if (isAuthenticate && checkExpireToken()) return <AuthenticatedRouter />;
              // else return config.SSO ? <Redirect to="/login-sso" /> : <Redirect to="/QRCODEREDIRECT" />;
              else {
                // if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL01) return <Redirect to="/QRCODEREDIRECT" />;
                if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL01) return <Redirect to="/" />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL02) return <Redirect to="/login-sso" />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL03) return <Redirect to="/login" />;
              }
            }}
          />
        </Switch>
      </Suspense>
      {/* <Footer /> */}
    </>
  );
};

export default AppRouter;
